import React, { useState } from "react"
import { getResource } from "../../config"

export default function ({title, imgUrl, description, hideButton}: {title: string, imgUrl: string, description?: string, hideButton?: boolean}) {
    const [collapsed, setCollapsed] = useState(false)

    return <div className='curtain'>
        <div className={`curtain-content ${collapsed ? 'curtain-content_collapsed' : ''}`}>
            <div className="curtain-content-left">
                <img src={getResource('curtain_tribal.png')} className="curtain-content-left__tribal"></img>
                {description
                   ? <span>
                        <h1>{title}</h1>
                        <p>{description}</p>
                   </span>
                   : <h1>{title}</h1>
                }
                <div className="curtain-content-left__trapezoid"/>
            </div>
            <div
                style={{backgroundImage: `url(${imgUrl})`}}
                className="curtain-content-right"
            />
        </div>
        {!hideButton &&
            <>
                <div
                    className='curtain-button'
                    onClick={() => setCollapsed(collapsed => !collapsed)}
                >
                    <i aria-hidden="true" className={collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}/>
                </div>

            </>
        }
    </div>
}